@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=['roboto']:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@layer components {
    .dashboard-right-menu-dropdown {
        @apply block py-2 text-gray-700 hover:bg-[#489BF6] hover:text-white font-[600] cursor-pointer rounded-md;
    }
    .login-page-buttons {
        @apply flex justify-center items-center border-[1px] border-[#000000] bg-[#FFF8F840] text-[#1C2347] font-[700] mb-3 max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-r from-blue-500 to-purple-600 hover:text-white w-full py-3 rounded-lg;
    }
    .message-container {
        @apply text-center fixed z-[9999999999] top-5 left-0 w-full max-md:top-2.5;
    }
    .message-container > div {
        @apply m-0 text-left bg-white min-w-message max-w-message inline-block py-5 px-4 rounded-md overflow-hidden shadow-message max-md:py-2.5 max-md:px-1.5 max-md:shadow-message2;
    }
    .message-container > div > div {
        @apply grid grid-cols-[55px_1fr_80px] items-center max-md:grid-cols-[45px_1fr_60px];
    }
    .message-container > div > div > div {
        @apply border-r-[1px] border-[#777777] pr-5 max-md:pr-2.5;
    }
    .message-container img {
        @apply w-10;
    }
    .message-container h4,
    .message-container h5,
    .message-container p {
        @apply m-0;
    }
    .message-container h4 {
        @apply font-medium text-xl mb-1;
    }
    .message-container h5 {
        @apply text-[#777777] text-base;
    }
    .message-container p {
        @apply text-[#ABABAB] text-base cursor-pointer text-center pl-4 max-md:pl-0;
    }
    .message-container p:hover {
        @apply text-[#777777];
    }
    .error-message-box {
        @apply border-l-8 border-[#FE2343] text-[#FE2343];
    }
    .success-message-box {
        @apply border-l-8 border-[#24B056] text-[#24B056];
    }
    .input-number {
        /* Ensure the input is wide enough to accommodate the arrows */
        width: 2.5rem;
        /* Remove the default arrows on Firefox */
        -moz-appearance: textfield;
    }
    .ai-voice-cloning-navbar {
        @apply flex justify-center items-center rounded-r-[32px] rounded-l-[32px] py-3 text-[16px] leading-[19px] font-[500] text-[#969696]  mx-2 px-4 hover:bg-[#489BF636] hover:text-[#489BF6] cursor-pointer;
    }
    .ai-voice-cloning-navbar-active {
        @apply flex justify-center items-center rounded-r-[32px] rounded-l-[32px] py-3 text-[16px] leading-[19px] font-[500] mx-2 px-4 bg-[#489BF636] text-[#489BF6] cursor-pointer;
    }
    .ai-voice-cloning-navbar > img {
        @apply pr-2;
    }
    .ai-voice-cloning-navbar-active > img {
        @apply pr-2;
    }
    .speech-header-active {
        @apply text-[#489BF6] font-[500] text-[16px] leading-[19px] cursor-pointer text-center w-full bg-[#F4F6F8] border-[#489BF6] border-x-[1px] border-t-[1px];
    }
    .speech-header-active > div {
        @apply h-[7px] w-full bg-[#489BF6];
    }
    .speech-header-active > p {
        @apply py-4;
    }
    .speech-header-inactive {
        @apply text-[#A7A7A7] font-[500] text-[16px] leading-[19px] cursor-pointer  text-center w-full bg-[#FFFFFF1F] border-[#C1C3C0] border-x-[1px] border-t-[1px] border-b-[1px] border-b-[#489BF6];
    }
    .speech-header-inactive > div {
        @apply h-[7px] w-full bg-[#FFFFFF1F];
    }
    .speech-header-inactive > p {
        @apply py-4;
    }
    .gradient-border {
        border-width: 2px;
        border-style: solid;
        background-clip: padding-box; /* Ensure gradient doesn't extend to padding */
        color: transparent; /* Hide original text color */
        background-image: linear-gradient(
            96.69deg,
            #48a1fa -1.71%,
            #541cb0 98.6%
        );
    }
    /* Remove the spinner arrows on Chrome */
    .input-number::-webkit-inner-spin-button,
    .input-number::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
